<template>
    <div class="widget-wrapper" :key="refreshKey" :style="[element.styles]">
        <div class="widget" v-if="!iframeLoaded || !this.element?.settings?.url">
            <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M27 53c14.36 0 26-11.64 26-26S41.36 1 27 1 1 12.64 1 27s11.64 26 26 26z" fill="#fff" stroke="#484848" stroke-width="1.75" stroke-miterlimit="10" />
                <path d="M46.057 9.313a30.816 30.816 0 0 1-19.24 6.7c-7.273 0-13.835-2.46-19.093-6.59M7.6 44.306a30.8 30.8 0 0 1 19.217-6.687c7.36 0 14.12 2.571 19.43 6.863" stroke="#484848" stroke-width="1.75" stroke-miterlimit="10" />
                <path d="M27.366 53c8.292 0 15.014-11.64 15.014-26S35.658 1 27.366 1c-8.292 0-15.014 11.64-15.014 26s6.722 26 15.014 26zM1 27h52M27.366 1v52" stroke="#484848" stroke-width="1.75" stroke-miterlimit="10" />
            </svg>
        </div>
        <iframe :src="element.settings.url" @load="iframeLoad" loading="lazy" title="Signage iFrame"
            crossorigin="anonymous" width="100%" height="100%" ref="iframe" frameborder="0" scrolling="no"
            allow="autoplay" aria-hidden="true" v-bind="iframeAttributes" :class="{ 'loading': !iframeLoaded }" />
    </div>
</template>

<script>
import { useOnline } from '@vueuse/core'

export default {
    name: 'IFrameWidget',
    props: ["element", "preview"],
    setup() {
        const online = useOnline()
        return { online }
    },
    data() {
        return {
            iframeLoaded: false,
            refreshKey: false,
            loadStartTime: 0
        }
    },
    mounted() {
        this.refreshKey = !this.refreshKey;
        this.startLoading();
    },
    methods: {
        startLoading() {
            console.log("Start loading");
            this.loadStartTime = performance.now();
        },
        iframeLoad() {
            this.iframeLoaded = false;
            const loadDuration = performance.now() - this.loadStartTime;
            console.log("Iframe loaded " + (loadDuration / 1000));
            this.loadStartTime = 0;
            if ((loadDuration / 1000) < 10 && this.element.settings?.url) {
                this.iframeLoaded = true;
               
            }

            if(!this.iframeLoaded && this.element.settings?.url){
                setTimeout(() => {
                    this.refreshKey = !this.refreshKey;
                }, 10000);
            } 
        },

    },
    watch: {
        "element.settings.url"() {
            this.refreshKey = !this.refreshKey;
        },
        online(status) {
            if(status) this.refreshKey = !this.refreshKey;
        },
    },
    computed: {
        iframeAttributes() {
            return !this.preview ? { sandbox: '' } : {};
        }
    }
}
</script>

<style lang="scss" scoped>
iframe {
    pointer-events: none;
}

.widget {
    width: 100%;
    height: 100%;
    background-color: #f4f4f4;
    display: flex;

    svg {
        margin: auto;
    }
}



.widget-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    background: #f4f4f4;
}

.widget-wrapper>* {
    width: 100%;
    height: 100%;
}

.widget-wrapper iframe.loading {
    width: 0%;
    height: 0%;
}
</style>